export const lineAnimation = (num1: number, num2: number, duration: number) => {
  return (
    {
      hidden: {
        strokeDasharray: num1,
        strokeDashoffset: num2,
      },
      visible: (custom: any) => ({
        strokeDashoffset: 0,
        transition: { ease: "linear", duration: duration, delay: custom * 0.2 },
      }),
    }
  )
}
