import { SVGProps } from "react";

export const DzenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    fill="none"
    viewBox="0 0 31 31"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12.294 11.196C14.601 8.88 14.897 3.433 14.877 1h.541c0 3.62.891 8.386 2.824 10.196 3.195 2.993 8.66 3.419 10.993 3.258v.784c-2.202 0-7.365.109-10.393 2.474-3.027 2.365-3.424 8.145-3.424 11.523h-.54c0-2.594-.41-8.434-3.004-11.04C9.278 15.588 3.544 15.138 1 15.238v-.784c2.804-.12 8.987-.941 11.294-3.258z"
    ></path>
  </svg>
);
