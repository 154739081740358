import styles from "../ProjectBlock/ProjectBlock.module.scss";

export const LineProject = () => {
  return (
    <svg
      width="1857"
      height="3426"
      viewBox="0 0 1857 3426"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles["project-line"]}
    >
      <path
        d="M1007.5 3425C1013.5 3334.5 996.148 3196 881.499 3059.5C766.851 2923 825.616 2805.62 951.499 2792.5C1096.8 2777.36 1235.84 2888.69 1331.5 2932C1792 3140.5 1825.5 2850 1710.5 2792.5C1595.5 2735 1647.71 2837.6 1678.5 2770.5C1793.69 2519.5 1430.69 2367.69 1054.5 2327C678.304 2286.31 420.019 2599 174.521 2429.5C92.5172 2372.88 34.3469 2270.01 14.2358 2184.51C-23.9155 2022.31 54.5536 1971.53 136.507 1978.01C301.008 1991.01 242.446 2078.39 215.507 2031.51C171.494 1949.51 266.008 1934.51 305.021 1955.51C344.034 1976.51 283.52 2042.51 174.521 1893.51C65.521 1744.51 42.6054 1573.51 196.063 1493.01C349.52 1412.51 449.521 1566.21 684.521 1542.01C829.094 1527.12 1033.88 1477.74 1078.02 1357.01C1097.35 1304.14 1091.77 1198.48 1032.09 1156.51C941.092 1092.51 990.02 1274.51 1298.02 1156.51C1606.02 1038.51 1780.66 658.005 1739.45 394.462C1695.06 110.603 1437.5 60.443 1446 135.443C1449.49 166.246 1534.5 156.034 1569.5 128.489C1604.5 100.943 1574 18.4696 1552 28.9881C1530 39.5065 1540.01 77.5353 1651 106.489C1714.51 123.057 1807.81 108.201 1862.5 2.49988"
        stroke="#F39224"
        strokeWidth="7"
      />
    </svg>
  );
};