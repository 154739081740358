import classNames from "classnames";
import styles from "./ProjectBlock.module.scss";
import { Container } from "../Container";
import { SecondaryTitle, MainTitle } from "../Titles";
import { Slider } from "../Slider";
import { projectCardData } from "../ProjectCard/projectCard.data";
import { ProjectCard } from "../ProjectCard";
import { motion } from "framer-motion";
import { lineAnimation } from "../../utils/animations";
import { LineProject} from "../Lines";
interface ProjectBlockProps {
  className?: string;
}

export const ProjectBlock = ({ className }: ProjectBlockProps) => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.15, once: true }}
      className={classNames(styles["project-section"], className)}
    >
      <motion.div custom={3} variants={lineAnimation(8961, -8961, 10)}><LineProject/></motion.div>
      <Container>
        <div className={styles["project-heading"]}>
          <SecondaryTitle>Кто мы и какими проектами</SecondaryTitle>
          <MainTitle variant="secondary">гордимся</MainTitle>
        </div>
        <div className={styles["project-block"]}>
          <div className={styles["project-slider"]}>
            <Slider />
          </div>
          <div className={styles["project-items"]}>
            {projectCardData.map((item, index) => {
              return (
                <ProjectCard key={index} className={styles["project-item"]} {...item} />
              );
            })}
          </div>
        </div>
      </Container>
    </motion.section>
  );
};
