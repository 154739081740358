import { SVGProps } from "react";

export const ArrowTopIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    fill="none"
    viewBox="0 0 26 26"
    {...props}
  >
    <path
      stroke="#07B6F0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M23.667 13L13.001 2.333 2.334 13M13 23.667V2.333"
    ></path>
  </svg>
);
