import classNames from "classnames";
import styles from "./ProjectCard.module.scss";
import ReactPlayer from "react-player";
import { PlayIcon } from "../Icons";
import "./video.scss";

interface ProjectCardProps {
  className?: string;
  title?: string;
  type?: string;
  videoUrl?: string;
  imageUrl?: string;
  href?: string;
}

export const ProjectCard = ({
  className,
  title,
  type = "image",
  videoUrl,
  imageUrl,
  href
}: ProjectCardProps) => {
  return (
    <div className={classNames(styles["project-card"], className)}>
      {type === "image" ? (
        <a href={href} target="blank">
          <div
          className={styles["project-card-img"]}
          style={{ background: `url(${imageUrl}) center center no-repeat`, backgroundSize: 'cover' }}
            ></div>
        </a>
      ) : type === "video" ? (
        <ReactPlayer
          className={styles["project-card-video"]}
          controls={true}
          playIcon={<PlayIcon />}
          url={videoUrl}
          height="200px"
          light={imageUrl}
          width="auto"
        />
      ) : null}
      <a href={href} target="blank"><h4 className={styles["project-card__title"]}>{title}</h4></a>
    </div>
  );
};
