import { BookIcon, CupIcon, DiscountIcon, GamepadIcon, MikeIcon, SofaIcon, SportIcon, ThumbIcon, RocketIcon } from "../Icons/Features";

export const featureData = [
  {
    icon: <CupIcon/>,
    title: "Well-being программы",
    descr: "Уверенность в завтрашнем дне и возможность обратиться за профессиональной помощью. Качественный пакет ДМС, карьерный центр, психологические и финансовые консультации от партнёров и экспертов компании."
  },
  {
    icon: <GamepadIcon/>,
    title: "Киберспортивное комьюнити",
    descr: "ЛАНИТ драйвит киберспорт среди сотрудников: чемпионаты, турниры, командные тренировки, буткемпы и многое другое. Играем во всё: от Dota 2 и CS:GO до Apex и Hearthstone."
  },
  {
    icon: <SportIcon/>,
    title: "Спортивное комьюнити",
    descr: "Йога, беговой, лыжный и шахматный клубы — выбирайте, что вам нравится. Для тех, кто любит командный спорт, организованы тренировки по футболу, волейболу и баскетболу."
  },
  {
    icon: <DiscountIcon/>,
    title: "Гибкая система скидок",
    descr: "Наши сотрудники получают персональную скидочную карту и другие скидки от партнёров и доступ к гибкой системе корпоративных скидок (фитнес-клубы, кинотеатры, розничные сети и многое другое)."
  },
  {
    icon: <BookIcon/>,
    title: "Среда для развития",
    descr: "Большие возможности для комплексного развития своих навыков: вебинары с внешними экспертами, корпоративный университет, профессиональные курсы и митапы, а также доступ к онлайн-библиотеке МИФ."
  },
  {
    icon: <MikeIcon/>,
    title: "Сообщества спикеров и авторов",
    descr: "Каждый сотрудник может присоединиться к одному из профессиональных сообществ: стать спикером на внутренних и внешних конференциях или автором публикаций на Хабре. Участникам сообществ помогают опытные наставники и эксперты."
  },
  {
    icon: <ThumbIcon/>,
    title: "Корпоративные мероприятия",
    descr: "Воркшопы, курсы и митапы — всё, что нужно для расширения сети знакомств, усиления экспертизы или отдыха от рабочих задач."
  },
  {
    icon: <RocketIcon/>,
    title: "Корпоративный акселератор",
    descr: "Развитие будущих продакт менеджеров из числа сотрудников и сопровождение проектов от идеи до реализации. "
  },
  {
    icon: <SofaIcon/>,
    title: "Комфортные офисы по всей стране",
    descr: "Удобные офисные пространства, где есть всё, что нужно для работы. "
  },
]