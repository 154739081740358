export const projectCardData = [
  {
    imageUrl: "https://i.ibb.co/y6jC8Qv/DSC-6678.jpg",
    title: "Исследование «Мифы и реальность о поколении зумеров»",
    link: `${window.location.origin}/about/Pages/generation-research.aspx`,
    type: "image",
    href: `${window.location.origin}/about/Pages/generation-research.aspx`,
  },
  {
    imageUrl: "https://i.ibb.co/ChbQP9x/image.jpg",
    title: "ЛАНИТ – лучший IT-работодатель России по версии портала«Хабр Карьера»",
    link: `${window.location.origin}/press/archive/lanit-luchshiy-it-rabotodatel-rossii/`,
    type: "image",
    href: `${window.location.origin}/press/archive/lanit-luchshiy-it-rabotodatel-rossii/`,
  },
  {
    // imageUrl: "https://i.ibb.co/gyQHGMV/86.png",
    imageUrl: "https://i.ibb.co/qpzWctf/2.jpg",
    title: "Смотрите митап проекта «Своя Среда» в записи!",
    // link: `https://clc.to/yEs52A`,
    link: `https://www.youtube.com/live/pGcOj4r0rhs?si=E9x7_B02pW-CXZeK`,
    type: "image",
    // href: `https://clc.to/yEs52A`,
    href: `https://www.youtube.com/live/pGcOj4r0rhs?si=E9x7_B02pW-CXZeK`,
  },
  // {
  //   imageUrl: "https://i.ibb.co/2SfK74B/image.jpg",
  //   title: "Александр Родионов о том, как работают корпоративные акселераторы",
  //   link: "https://www.lanit.ru/press/archive/lanit-luchshiy-it-rabotodatel-rossii/",
  //   videoUrl: "https://youtu.be/hZqZi80tWKk",
  //   type: "video"
  // },
  {
    imageUrl: "https://i.ibb.co/WzF7pb7/1.jpg",
    title: "Вместе лучше: как разнообразить жизнь сотрудников и взглянуть на работу под новым углом?",
    link: `${window.location.origin}/press/archive/lanit-luchshiy-it-rabotodatel-rossii/`,
    type: "image",
    href: `${window.location.origin}/blog/Pages/Together-is-better.aspx`
  },
]