import { SVGProps } from "react";

export const VkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="19"
    fill="none"
    viewBox="0 0 31 19"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth="0.5"
      d="M28.107 18.729h-3.012c-1.115 0-1.674-.624-2.603-1.656-.366-.406-.815-.906-1.41-1.503-1.644-1.584-2.34-1.744-2.597-1.744-.01.067-.018.175-.018.343v2.7c0 1.543-1.134 1.86-2.84 1.86-3.572 0-7.217-2.17-9.75-5.806C2.229 7.809 1 3.653 1 2.542c0-.979.628-1.54 1.72-1.54h3.012c1.322 0 1.725.843 1.999 1.633 1.356 3.952 3.354 6.896 4.186 7.484.007-.09.012-.208.012-.367V5.317c-.056-1.004-.39-1.417-.684-1.78-.24-.297-.513-.635-.513-1.155 0-.663.556-1.38 1.455-1.38h4.732c.707 0 1.548.31 1.548 1.794v5.967c0 .028.002.053.003.076.156-.107.377-.31.695-.626 2.036-2.278 3.537-5.897 3.552-5.934.359-.84 1.03-1.279 1.947-1.279h3.012c.651 0 1.126.195 1.41.578.19.255.373.708.16 1.415-.388 1.788-3.767 6.584-4.152 7.125-.03.048-.146.238-.161.303.004 0 .029.067.143.216.139.191.482.527.813.853.41.402.874.858 1.243 1.294 1.625 1.827 2.4 2.92 2.67 3.763.226.78.027 1.27-.182 1.546-.214.292-.652.636-1.513.636zm-9.54-6.28c.623 0 1.523.254 3.478 2.138a37.81 37.81 0 011.471 1.565c.9 1.004 1.12 1.2 1.578 1.2h3.011c.285 0 .401-.064.416-.082 0 0 .035-.084-.033-.317-.253-.787-1.412-2.16-2.393-3.265-.354-.418-.785-.84-1.168-1.212-.425-.416-.76-.745-.95-1.012-.686-.882-.418-1.476-.026-2.106 1.02-1.44 3.674-5.38 3.965-6.706.04-.14.05-.218.05-.255-.026.003-.118-.018-.29-.018h-3.012c-.411 0-.56.16-.677.434-.06.147-1.6 3.862-3.821 6.347-.707.709-1.207 1.155-1.917 1.155-.558 0-1.158-.486-1.158-1.55V2.798c0-.265-.032-.376-.047-.415-.015.006-.052-.002-.125-.004h-4.732c-.068.053.05.198.129.295.362.447.907 1.123.99 2.607l.001 4.473c0 .55 0 1.838-1.247 1.838-1.766 0-4.403-4.94-5.628-8.505-.245-.706-.36-.706-.698-.706H2.722c-.27 0-.348.052-.35.052.006.593.86 4.41 4.63 9.699 2.28 3.271 5.503 5.222 8.627 5.222 1.463 0 1.463-.203 1.463-.483v-2.7c0-.853.175-1.723 1.476-1.723z"
    ></path>
  </svg>
);
