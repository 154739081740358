import classNames from "classnames";
import { useEffect } from "react";
import styles from "./MobileMenu.module.scss";
import { HeaderList } from "../Header/HeaderList";
import { HeaderContact } from "../Header/HeaderContact";
import { HeaderSocial } from "../Header/HeaderSocial";

interface MobileMenuProps {
  className?: string;
  isOpenMenu?: boolean;
}

export const MobileMenu = ({className, isOpenMenu=false}:MobileMenuProps) => {
  useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    isOpenMenu ? body.style.overflow = "hidden" : body.style.overflow = "auto";
  },[isOpenMenu])

  return (
    <div className={classNames(styles['mobile-menu'], {
      [styles["closed"]]: isOpenMenu === false,
      [styles["opened"]]: isOpenMenu === true,
    } ,className)}>
      <HeaderList className={styles['mobile-menu-list']}/>
      <HeaderSocial className={styles['mobile-menu-social']}/>
      <HeaderContact className={styles['mobile-menu-contact']}/>
    </div>
  )
}