import classNames from "classnames";
import styles from "./AboutBlock.module.scss";
import { Container } from "../Container";
import { Slider } from "../Slider";
import { SecondaryTitle } from "../Titles";
import { motion } from "framer-motion";
import { lineAnimation } from "../../utils/animations";
import { LineAbout} from "../Lines";

interface AboutBlockProps {
  className?: string;
}

export const AboutBlock = ({ className }: AboutBlockProps) => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.15, once: true }}
      className={classNames(styles["about-section"], className)}
      id="section-2"
    >
      <motion.div custom={5} variants={lineAnimation(4927, -4927, 4)}><LineAbout/></motion.div>
      <Container>
        <div className={styles["about-block"]}>
          <div className={styles["about-block-slider"]}>
            <Slider type="simple" />
          </div>
          <div className={styles["about-block-wrapper"]}>
            <div className={styles["about-block-text"]}>
              <SecondaryTitle className={styles["about-block-text__title"]}>
                ЛАНИТ
              </SecondaryTitle>
              <p className={styles["about-block-text__descr"]}>
                «Лаборатория Новых Информационных Технологий» — группа
                IT-компаний, лидер российской отрасли информационных технологий.
                Наши подразделения и компании реализуют интересные и сложные
                проекты в самых разных сферах: от разработки и IT-консалтинга до
                интеграции и сервисного обслуживания.
              </p>
              <p className={styles["about-block-text__descr"]}>
                В команде ЛАНИТ более 14 000 сотрудников по всей России и за её
                пределами. Вместе мы делаем мир лучше каждый день — все наши
                проекты уникальны и масштабны, благодаря им каждый сотрудник
                ЛАНИТ может делать то, что имеет значение для множества людей.
              </p>
            </div>
            <img
              src="https://i.ibb.co/KxVVtcV/1.webp"
              alt="img"
              className={styles["about-block-text__img"]}
            />
          </div>
          <div className={styles["about-block-photo"]}>
            <img src="https://i.ibb.co/L93srHg/about-1.jpg" alt="img" />
          </div>
        </div>
      </Container>
    </motion.section>
  );
};
