import styles from "./Footer.module.scss";
import { footerSocialData } from "./footer.data";

interface FooterSocialItemProps {
  icon?: React.ReactNode;
  color?: string;
  link?: string;
}

const FooterSocialItem = ({icon, link, color}:FooterSocialItemProps) => {
  return (
    <a href={link} target="blank" className={styles['footer-social-item']} style={{background: color}}> 
      <div className={styles['footer-social-item__icon']}> 
        {icon}
      </div>
    </a>
  )
}

export const FooterSocial = () => {
  return (
    <div className={styles['footer-social-items']}>
      {
        footerSocialData.map((item, index) => {
          return (
            <FooterSocialItem
              {...item}
              key={index}
            />
          )
        })
      }
    </div>
  )
}