import {
  VkIcon,
  HabrIcon,
  TelegramIcon,
  YoutubeIcon,
  DzenIcon,
} from "../Icons/Header";
import { LandingSectionEnum } from "../../enums";
import { getSectionELementBySectionId } from "../../utils";

export const headerIconsData = [
  {
    icon: <TelegramIcon />,
    color: "#07B6F0",
    link: "https://t.me/lanit_in_search",
  },
  {
    icon: <VkIcon />,
    color: "#07B6F0",
    link: "https://vk.com/lanit_life",
  },
  {
    icon: <DzenIcon />,
    color: "#42616E",
    link: "https://dzen.ru/lanit",
  },
  {
    icon: <HabrIcon />,
    color: "#7FA1AE",
    link: "https://habr.com/ru/company/lanit/blog/",
  },
  {
    icon: <YoutubeIcon />,
    color: "#EC0000",
    link: "https://www.youtube.com/@LANITgk",
  },
];

export const headerListData = [
  {
    text: "Блог",
    link: `${window.location.origin}/blog/Pages/main.aspx`,
  },
  {
    text: "О нас",
    onClick: () => {
      window.scrollTo({
        top:
         document.getElementById("section-2")
            ?.offsetTop || 0,
        behavior: "smooth",
      });
    },
  },
  {
    text: "Вакансии",
    link: `${window.location.origin}/vacancy/Pages/new-search.aspx`,
  },
  {
    text: "Стажеры",
    link: `${window.location.origin}/landing`,
  },
];
