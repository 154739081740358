import styles from "../SearchBlock/SearchBlock.module.scss";

export const LineSearch = () => {
  return (
    <svg
      width="1836"
      height="912"
      viewBox="0 0 1836 912"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles["search-line"]}
    >
      <path
        d="M1631 4C1917 126 1848.5 421.098 1726.5 469C1604.5 516.902 1547 446 1585 446C1623 446 1804 791.5 1491 805.5C1178 819.5 774.001 336 294.196 733.5C62.1956 941.5 60.9996 680.5 102 692.5C143.001 704.5 52.0014 776.5 4 910.5"
        stroke="white"
        strokeWidth="7"
      />
    </svg>
  );
};