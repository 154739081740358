import { SVGProps } from "react";

export const GoToArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.8535 21.8426L21.1359 16.5092L15.8535 11.1759"
      stroke="#07B6F0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5703 16.5093H21.1351"
      stroke="#07B6F0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
