import styles from "./Carousel.module.scss";
import { GoToArrowIcon } from "../Icons";

interface CarouselItemProps {
  position?: string;
  subdvision?: string;
  city?: string;
  description?: any;
  idUrl?: string;
}

export const CarouselItem = ({
  position,
  subdvision,
  city,
  description,
  idUrl,
}: CarouselItemProps) => {
  const cutTegs = (str: any) => {
    // let string = str.replace(/<\/?[a-zA-Z]+>|&nbsp;/gi, "");
    let string = str.replace(/<([^>]+)>|&nbsp;/gi, "");
    //
    return string;
  };

  console.log(cutTegs(``));

  return (
    <div className={styles["carousel-item"]}>
      <h4 className={styles["carousel-item__title"]}>{position}</h4>
      <p className={styles["carousel-item__subdvision"]}>{subdvision}</p>
      <p className={styles["carousel-item__city"]}>{city}</p>
      {
        <ul className={styles["carousel-item-list"]}>
          {cutTegs(description)
            .split(";")
            .map((item: any, index: any) => {
              return (
                <li className={styles["carousel-item-list__item"]} key={index}>
                  {
                    (item.length > 1 && index < 3) ? <div className={styles["carousel-item-list__itemcircle"]}></div> : null
                  }
                  {
                    index < 3 && <p className={styles["carousel-item-list-text"]}>{item}</p>
                  }
                </li>
              );
            })}
        </ul>
      }
      <a
        href={`${window.location.origin}${idUrl}`}
        target="blank"
        className={styles["carousel-item__link"]}
      >
        Узнать детали
        <GoToArrowIcon />
      </a>
    </div>
  );
};
