import { SVGProps } from "react";

export const BurgerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="20"
    fill="none"
    viewBox="0 0 27 20"
    {...props}
  >
    <path
      stroke="#07B6F0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M1 1h25M1 10h25M1 19h25"
    ></path>
  </svg>
);
