import classNames from "classnames"
import styles from "./Header.module.scss";
import { headerIconsData } from "./header.data";
import { HeaderSocialItem } from "./HeaderSocialItem";

interface HeaderSocialProps {
  className?: string;
}

export const HeaderSocial = ({className}:HeaderSocialProps) => {
  return (
    <div className={classNames(styles['header-social'], className)}>
      {
        headerIconsData.map((item, index) => {
          return (
            <HeaderSocialItem
              color={item.color}
              icon={item.icon}
              link={item.link}
              key={index}
            />
          )
        })
      }
    </div>
  )
}