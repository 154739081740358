import { SVGProps } from "react";

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="48"
    fill="none"
    viewBox="0 0 49 48"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M22.344 38c8.974 0 16.25-7.163 16.25-16S31.318 6 22.344 6c-8.975 0-16.25 7.163-16.25 16s7.275 16 16.25 16zM42.656 42l-8.836-8.7"
    ></path>
  </svg>
);
