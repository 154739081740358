import classNames from "classnames"
import styles from "./Carousel.module.scss";
import { CarouselItem } from "./CarouselItem";
import axios from 'axios';
import { useEffect, useState } from "react";
import { IVacancy } from "../../types/vacancy";
import { Swiper, SwiperSlide } from "swiper/react";
import "./carousel-swiper.css";
import "swiper/css";
import "swiper/css/pagination";

interface CarouselProps {
  className?: string;
}

// http://jobtest.lanit.ru/vacancy/_vti_bin/Lanit.Job.Vacancy.External/VacancyService.svc/GetVacancyForWidget
// http://jobtest.lanit.ru/  _landing/jobtest.lanit.ru  /vacancy/_vti_bin/Lanit.Job.Vacancy.External/VacancyService.svc/GetVacancyForWidget
// http://jobtest.lanit.ru/vacancy/_vti_bin/Lanit.Job.Vacancy.External/VacancyService.svc/GetVacancyForWidget

export const Carousel = ({className}:CarouselProps) => {
  const [vacancies, setVacancies] = useState<IVacancy[]>([]);

  useEffect(() => {
    axios.get(`${window.location.origin}/vacancy/_vti_bin/Lanit.Job.Vacancy.External/VacancyService.svc/GetVacancyForWidget`)
      .then(function ({data}) {
        setVacancies(data.vacancy);
      })
      .catch(function (error: any) {
        console.error(error);
      });
  }, []) 

  return (
    <div className={classNames(styles['carousel'], className)}>
      <Swiper
        centeredSlides={true}
        spaceBetween={60}
        grabCursor={true}
        pagination={{
          type: 'fraction',
        }}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1.25,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1279: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
          1921: {
            slidesPerView: 6,
            spaceBetween: 50,
            centeredSlides: false
          },
        }}
      >
        {
          vacancies?.map((item: any) => {
            return (
              <SwiperSlide style={{height: "auto"}}>
                <CarouselItem
                  position={item.position}
                  subdvision={item.subdvision}
                  city={item.city}
                  key={item.extId}
                  description={item.description}
                  idUrl={item.idUrl}
                />
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </div>
  )
}