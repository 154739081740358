export const simpleSliderData = [
  {
    id: 1,
    imageUrl: "https://i.ibb.co/JcnXF7n/3.jpg",
  },
  {
    id: 2,
    imageUrl: "https://i.ibb.co/5jGZBDB/2.jpg",
  },
  {
    id: 3,
    imageUrl: "https://i.ibb.co/b6Y0JsK/1.jpg",
  },
  {
    id: 4,
    imageUrl: "https://i.ibb.co/2ctXPN5/4.jpg",
  },
  {
    id: 5,
    imageUrl: "https://i.ibb.co/FgThNfm/5.jpg",
  },
];



export const fullSliderData = [
  {
    id: 1,
    imageUrl: "https://i.ibb.co/dLSsX8G/image.jpg",
    text: "Проложили беспроводные сети на стадионах Чемпионата мира по футболу 2018",
  },
  {
    id: 2,
    imageUrl: "https://i.ibb.co/yVwPh4P/image-32.jpg",
    text: "Разработали суперсервис для оформления Европротокола онлайн",
  },
  {
    id: 3,
    imageUrl: "https://i.ibb.co/VJsQhmT/image.jpg",
    text: "Внедрили телемедицинский сервис для борьбы с COVID-19 в больницах по всей России",
  },
  {
    id: 4,
    imageUrl: "https://i.ibb.co/HpJqYMr/image.jpg",
    text: "Обеспечили техническое оснащение космодрома «Восточный»",
  },
  {
    id: 5,
    imageUrl: "https://i.ibb.co/RSrS6sT/1.jpg",
    text: "Оснастили здание парка «Зарядье» мультимедийными системами",
  },
  {
    id: 6,
    imageUrl: "https://i.ibb.co/LhR4M3V/image.png",
    text: "Модернизировали метеорологическую сеть Росгидромета",
  },
  {
    id: 7,
    // imageUrl: "https://i.ibb.co/K0tLxzz/SOLUT.png",
    imageUrl: "https://i.ibb.co/5vNct2t/solut.jpg",
    text: "Разработали интеллектуальную систему мониторинга труда SOLUT",
  },
];
