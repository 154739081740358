import classNames from "classnames";
import styles from "./SecondaryTitle.module.scss";
interface SecondaryTitleProps {
  className?: string;
  children?: React.ReactNode;
  variant?: string;
}

export const SecondaryTitle = ({
  className,
  variant = "dark",
  children,
}: SecondaryTitleProps) => {
  return (
    <h3
      className={classNames(
        styles["secondary-title"],
        {
          [styles["secondary-title__dark"]]: variant === "dark",
          [styles["secondary-title__light"]]: variant === "light",
        },
        className
      )}
    >
      {children}
    </h3>
  );
};
