import classNames from "classnames";
import styles from "./Container.module.scss";

interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
}

export const Container = ({ className, children }: ContainerProps) => {
  return (
    <div className={classNames(styles["container"], className)}>{children}</div>
  );
};
