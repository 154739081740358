import { SVGProps } from "react";

export const VkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="77"
    height="48"
    fill="none"
    viewBox="0 0 77 48"
    {...props}
  >
    <path
      fill="#fff"
      d="M72.002 47.093h-8c-2.96 0-4.445-1.657-6.913-4.399-.973-1.079-2.165-2.406-3.748-3.993-4.366-4.208-6.216-4.632-6.896-4.632-.025.18-.047.464-.047.91v7.174c0 4.099-3.013 4.94-7.543 4.94-9.492 0-19.173-5.766-25.9-15.423C3.264 18.087 0 7.046 0 4.095 0 1.495 1.667.004 4.57.004h8c3.51 0 4.581 2.24 5.31 4.34 3.6 10.497 8.91 18.317 11.118 19.879.018-.238.033-.552.033-.977v-11.78c-.15-2.665-1.035-3.762-1.817-4.728-.64-.79-1.364-1.685-1.364-3.067 0-1.762 1.477-3.667 3.864-3.667h12.57c1.88 0 4.114.826 4.114 4.767v15.85c0 .073.003.14.007.201.413-.285 1.002-.822 1.846-1.663 5.408-6.051 9.393-15.664 9.434-15.762C58.639 1.167 60.423 0 62.858 0h8c1.73 0 2.99.52 3.748 1.536.504.676.987 1.879.424 3.758-1.031 4.75-10.007 17.488-11.031 18.925-.077.128-.388.633-.428.804.011 0 .077.18.38.574.37.509 1.28 1.4 2.161 2.267 1.09 1.068 2.322 2.278 3.302 3.437 4.314 4.852 6.373 7.759 7.093 9.996.6 2.07.07 3.371-.486 4.106-.567.775-1.73 1.69-4.019 1.69zM46.665 30.409c1.652 0 4.044.676 9.235 5.682a100.62 100.62 0 013.909 4.157c2.391 2.665 2.973 3.188 4.19 3.188h8c.757 0 1.064-.172 1.104-.22 0 0 .092-.222-.088-.84-.672-2.091-3.751-5.737-6.358-8.673-.94-1.111-2.084-2.23-3.1-3.22-1.13-1.105-2.019-1.979-2.527-2.688-1.82-2.344-1.108-3.92-.066-5.594 2.71-3.825 9.759-14.293 10.53-17.814.106-.372.132-.577.132-.676-.066.007-.31-.047-.768-.047h-8c-1.093 0-1.488.424-1.799 1.151-.16.391-4.248 10.26-10.15 16.86-1.879 1.882-3.206 3.067-5.093 3.067-1.48 0-3.074-1.29-3.074-4.117V4.775c0-.702-.084-.998-.125-1.1-.04.014-.139-.008-.332-.011h-12.57c-.18.139.135.526.343.782.962 1.188 2.41 2.984 2.629 6.925l.004 11.883c0 1.459 0 4.88-3.313 4.88-4.691 0-11.696-13.121-14.95-22.591-.651-1.876-.955-1.876-1.854-1.876h-8c-.717 0-.925.14-.929.14.015 1.575 2.282 11.714 12.3 25.761C22 38.26 30.559 43.44 38.859 43.44c3.886 0 3.886-.538 3.886-1.283v-7.174c0-2.263.465-4.574 3.92-4.574z"
    ></path>
  </svg>
);
