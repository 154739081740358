import classNames from "classnames";
import styles from "./MainTitle.module.scss";

interface MainTitleProps {
  className?: string;
  children?: React.ReactNode;
  variant?: string;
}

export const MainTitle = ({ className, variant = "primary", children }: MainTitleProps) => {
  return (
    <h2
      className={classNames(
        styles["main-title"],
        {
          [styles["main-title__primary"]]: variant === "primary",
          [styles["main-title__secondary"]]: variant === "secondary",
          [styles["main-title__light"]]: variant === "light",
        },
        className
      )}
    >
      {children}
    </h2>
  );
};
