import classNames from "classnames";
import styles from "./Textfield.module.scss";

interface TextfieldProps {
  className?: string;
  onChange?: any;
  onBlur?: any;
  placeholder?: string;
  value?: string;
  helperText?: string;
  error?: boolean;
}

export const Textfield = ({
  className,
  placeholder,
  value,
  onChange,
  onBlur,
  helperText,
  error,
}: TextfieldProps) => {
  return (
    <div className={styles["textfield-wrapper"]}>
      <input
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        className={classNames(styles["textfield"],{
          [styles["textfield__error"]]: error === true,
        }, className)}
      />
      {
        error && (
          <p className={styles["textfield__helpertext"]}>{helperText}</p>
        )
      }
    </div>
  );
};
