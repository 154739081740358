import classNames from "classnames";
import styles from "./Header.module.scss";
import { PhoneIcon, MailIcon } from "../Icons/Header";

interface HeaderContactProps {
  className?: string;
}

export const HeaderContact = ({className}:HeaderContactProps) => {
  return (
    <div className={classNames(styles['header-contact'], className)}>
      <div className={styles['header-contact-item']}>
        <div className={styles['header-contact-item-icon']}>
          <PhoneIcon/>
        </div>
        <a href="tel:+74959676650" className={styles['header-contact-item-link']}>
          +7 (495) 967-66-50
        </a>
      </div>
      <div className={styles['header-contact-item']}>
        <div className={styles['header-contact-item-icon']}>
          <MailIcon/>
        </div>
        <a href="mailto:job@lanit.ru" className={styles['header-contact-item-link']}>
          job@lanit.ru
        </a>
      </div>
    </div>
  )
}