import classNames from "classnames";
import styles from "./Button.module.scss";

interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  style?: any;
}

export const Button = ({
  className,
  children,
  onClick = () => {},
  style
}: ButtonProps) => {
  return (
    <button style={style} onClick={onClick} className={classNames(styles["button"], className)}>
      {children}
    </button>
  );
};
