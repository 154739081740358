import { SVGProps } from "react";

export const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      stroke="#939EA8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M32 16L16 32M16 16l16 16"
    ></path>
  </svg>
);
