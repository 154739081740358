import { SVGProps } from "react";

export const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="56"
    fill="none"
    viewBox="0 0 66 56"
    {...props}
  >
    <path
      fill="#fff"
      d="M48.773 55.036a1.87 1.87 0 01-1.191-.426l-21.728-17.8a1.888 1.888 0 01-.693-1.413 1.893 1.893 0 01.622-1.444L41.908 19.4a1.878 1.878 0 012.661.136 1.88 1.88 0 01-.135 2.662l-14.5 13.082 20.03 16.412a1.885 1.885 0 01-1.19 3.344z"
    ></path>
    <path
      fill="#fff"
      d="M51.062 55.737c-.999 0-2.073-.313-3.2-.935l-12.39-9.957-6.348 5.855c-.883.863-1.96 1.911-4.106 1.911-2.64 0-3.254-1.39-3.657-2.82-.072-.245-.15-.531-.268-.86l-4.547-14.963-13.03-4.064C.449 28.96.037 27.08.004 26.318c-.053-1.267.607-3.072 4.049-4.452l55.98-21.6c1.602-.625 3.483-.12 4.569 1.218.765.939 1.06 2.16.81 3.352l-9.67 46.279c-.867 4.17-3.563 4.622-4.679 4.622zM35.39 40.471c.418 0 .837.14 1.18.419l13.384 10.79c1.73.826 1.885-.32 2.1-1.334l9.67-46.275-.237-.306-56.048 21.61c-.66.265-1.093.525-1.357.717.14.068.324.14.554.211l13.992 4.366c.591.189 1.059.656 1.24 1.252l4.803 15.82c.125.331.23.708.32 1.025a.498.498 0 01.02.072c.5-.004.765-.14 1.462-.822l7.639-7.05a1.916 1.916 0 011.278-.495z"
    ></path>
  </svg>
);
