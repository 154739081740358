import classNames from "classnames";
import styles from "./SearchBlock.module.scss";
import { Container } from "../Container";
import { Searchfield } from "../Searchfield";
import { SecondaryTitle, MainTitle } from "../Titles";
import { motion } from "framer-motion";
import { lineAnimation } from "../../utils/animations";
import { LineSearch } from "../Lines";

interface SearchBlockProps {
  className?: string;
}

export const SearchBlock = ({ className }: SearchBlockProps) => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={classNames(styles["search-section"], className)}
    >
      <motion.div custom={5} variants={lineAnimation(3137, 3137, 1.5)}>
        <LineSearch />
      </motion.div>
      <Container>
        <div className={styles["search-block"]}>
          <div className={styles["search-heading"]}>
            <SecondaryTitle variant="light">Найти интересную</SecondaryTitle>
            <MainTitle variant="light">вакансию</MainTitle>
          </div>
          <Searchfield />
          <div className={styles["search-buttons"]}>
            <a
              href={`${window.location.origin}/vacancy/Pages/new-search.aspx`}
              target="blank"
              className={styles["search__button"]}
            >
              Открытые вакансии
            </a>
            <a
              href={`${window.location.origin}/vacancy/Pages/new-search.aspx?show-resume-modal=1`}
              target="blank"
              className={styles["search__button"]}
            >
              Отправить резюме
            </a>
            <a
              href={`${window.location.origin}/vacancy/Pages/new-search.aspx#isyoung=1`}
              target="blank"
              className={styles["search__button"]}
            >
              Молодым специалистам
            </a>
          </div>
        </div>
      </Container>
    </motion.section>
  );
};
