import { SVGProps } from "react";

export const HabrIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="24"
    fill="none"
    viewBox="0 0 21 24"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1.5a.5.5 0 01.5-.5h4.48a.5.5 0 01.5.5v8.483h8.04V1.5a.5.5 0 01.5-.5h4.48a.5.5 0 01.5.5v21a.5.5 0 01-.5.5h-4.48a.5.5 0 01-.5-.5v-7.567H6.48V22.5a.5.5 0 01-.5.5H1.5a.5.5 0 01-.5-.5v-21z"
    ></path>
  </svg>
);
