import classNames from "classnames";
import styles from "./Footer.module.scss";
import { Container } from "../Container";
import { SecondaryTitle } from "../Titles";
import { FooterSocial } from "./FooterSocial";
import { motion } from "framer-motion";
import { lineAnimation } from "../../utils/animations";
import { LineFooter } from "../Lines";

interface FooterProps {
  className?: string;
}

export const Footer = ({ className }: FooterProps) => {
  return (
    <motion.footer
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.15, once: true }}
      className={classNames(styles["footer"], className)}
    >
      <motion.div custom={5} variants={lineAnimation(1117, 1117, 1)}>
        <LineFooter />
      </motion.div>
      <Container>
        <div className={styles["footer-block"]}>
          <h3 className={styles["footer__title"]}>Хотите задать вопрос?</h3>
          <div className={styles["footer-block-social"]}>
            <p className={styles["footer-block-social__title"]}>
              Еще больше о нас в соцсетях:
            </p>
            <FooterSocial />
          </div>
        </div>
        <div className={styles["footer-block"]}>
          <div className={styles["footer-block-text"]}>
            <p className={styles["footer-block-text__title"]}>
              Мы отвечаем на вопросы тут:
            </p>
            <a
              href="mailto:job@lanit.ru "
              className={styles["footer-block-text__link"]}
            >
              job@lanit.ru
            </a>
          </div>
        </div>
        <div className={styles["footer-bottom"]}>
          <p className={styles["footer__copyright"]}>
            © ЛАНИТ 1989 – {new Date().getFullYear()}
          </p>
          <a
            target="blank"
            href="https://job.lanit.ru/Shared%20Documents/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%9B%D0%90%D0%9D%D0%98%D0%A2_Privacy%20Policy.pdf"
            className={styles["footer-policy"]}
          >
            Политика обработки персональных данных
          </a>
        </div>
      </Container>
    </motion.footer>
  );
};
