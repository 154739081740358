import classNames from "classnames";
import styles from "./CarouselBlock.module.scss";
import { SecondaryTitle, MainTitle } from "../Titles";
import { Container } from "../Container";
import { Carousel } from "../Carousel";

interface CarouselBlockProps {
  className?: string;
}

export const CarouselBlock = ({ className }: CarouselBlockProps) => {
  return (
    <div className={classNames(styles["carousel-section"], className)}>
      <Container>
        <div className={styles["carousel-heading"]}>
          <div className={styles["carousel-heading__title"]}>
            <SecondaryTitle>Лучшие</SecondaryTitle>
            <MainTitle>вакансии</MainTitle>
            <SecondaryTitle>уже ждут вас</SecondaryTitle>
          </div>
          <div className={styles["carousel-heading-text"]}>
            <p className={styles["carousel-heading-text__descr"]}>
              Ищете уникальную вакансию?
            </p>
            <a href={`${window.location.origin}/vacancy/Pages/new-search.aspx`} target="blank" className={styles["carousel-heading-text__link"]}>
              Посмотрите полный список
            </a>
          </div>
        </div>
      </Container>
      <Carousel />
    </div>
  );
};
