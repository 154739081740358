import classNames from "classnames";
import styles from "./FeatureCard.module.scss";

interface FeatureCardProps {
  className?: string;
  title?: string;
  descr?: string;
  icon?: React.ReactNode;
}

export const FeatureCard = ({ className, title, descr, icon }: FeatureCardProps) => {
  return (
    <div className={classNames(styles["feature-card"], className)}>
      <div className={styles["feature-card__icon"]}>
        {icon}
      </div>
      <h3 className={styles["feature-card__title"]}>{title}</h3>
      <p className={styles["feature-card__descr"]}>{descr}</p>
    </div>
  );
};
