import { SVGProps } from "react";

export const DzenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79"
    height="79"
    fill="none"
    viewBox="0 0 79 79"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M32 29.083C38.128 22.93 38.915 8.463 38.862 2h1.436c0 9.615 2.366 22.276 7.5 27.083 8.487 7.95 23.004 9.081 29.202 8.654v2.084c-5.851 0-19.564.288-27.606 6.57C41.35 52.673 40.298 68.026 40.298 77h-1.436c0-6.891-1.085-22.404-7.979-29.327C23.989 40.75 8.755 39.553 2 39.821v-2.084c7.447-.32 23.872-2.5 30-8.654z"
    ></path>
  </svg>
);
