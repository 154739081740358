import classNames from "classnames";
import styles from "./FeatureBlock.module.scss";
import { featureData } from "./feature.data";
import { FeatureCard } from "../FeatureCard";
import { Container } from "../Container";
import { MainTitle } from "../Titles/MainTitle";
import { SecondaryTitle } from "../Titles/SecondaryTitle";

interface FeatureBlockProps {
  className?: string;
}

export const FeatureBlock = ({ className }: FeatureBlockProps) => {
  return (
    <div className={classNames(styles["feature-section"], className)}>
      <Container>
        <div className={styles['feature-heading']}>
          <div className={styles['feature-heading__title']}>
            <SecondaryTitle>Специальные</SecondaryTitle>
            <MainTitle variant="secondary">бенефиты</MainTitle>
            <SecondaryTitle>для наших сотрудников</SecondaryTitle>
          </div>
          <div className={styles['feature-heading__descr']}>
          Чтобы ваше пребывание <br /> в компании было максимально <br /> комфортным и приятным
          </div>
        </div>
        <div className={styles["feature-block"]}>
          {featureData.map((item, index) => {
            return <FeatureCard key={index} className={styles['feature-card']} {...item} />;
          })}
        </div>
      </Container>
    </div>
  );
};
