import classNames from "classnames";
import styles from "./Header.module.scss";

interface HeaderSocialIconProps {
  className?: string;
  icon?: any;
  link?: string;
  color?: string;
}

export const HeaderSocialItem = ({
  className,
  icon,
  link,
  color
}: HeaderSocialIconProps) => {
  return (
    <a
      href={link}
      target="blank"
      className={classNames(styles["header-social-item"], className)}
      style={{background: color}}
    >
      {icon}
    </a>
  );
};
