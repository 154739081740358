import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import { simpleSliderData, fullSliderData } from "./slider.data";
import styles from "./Slider.module.scss";
import "./custom.slider.css";
import { Slide } from "./Slide";

interface SliderProps {
  type?: string;
}

export const Slider = ({ type = "full" }: SliderProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + index + "</span>";
    },
  };

  return (
    <div className={styles["slider-wrapper"]}>
      <Swiper
        pagination={pagination}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        className={`slider ${
          type === "full"
            ? "slider--full"
            : type === "simple"
            ? "slider--simple"
            : ""
        }`}
      >
        {type === "simple"
          ? simpleSliderData.map((slide, index) => {
              return (
                <SwiperSlide key={slide.id}>
                  <Slide key={index} imageUrl={slide.imageUrl} type="simple" />
                </SwiperSlide>
              );
            })
          : type === "full"
          ? fullSliderData.map((slide, index) => {
              return (
                <SwiperSlide key={slide.id}>
                  <Slide
                    imageUrl={slide.imageUrl}
                    text={slide.text}
                    type="full"
                    key={index}
                  />
                </SwiperSlide>
              );
            })
          : null}
      </Swiper>
    </div>
  );
};
