import classNames from "classnames";
import styles from "./TelegramModal.module.scss";
import { TelegramIcon, DeleteIcon } from "../Icons";
import { Button } from "../Buttons";

import hhimage from "../../../src/assets/images/hh.jpg";

interface TelegramModalProps {
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

export const TelegramModal = ({
  className,
  isOpen,
  onClose = () => {},
}: TelegramModalProps) => {
  return (
    <div
      className={classNames(
        styles["modal-telegram"],
        {
          [styles["modal-telegram_active"]]: isOpen,
        },
        className
      )}
    >
      <div className={styles["modal-telegram__close"]} onClick={onClose}>
        <DeleteIcon />
      </div>
      <TelegramIcon />
      {/* <img style={{borderRadius: '100%'}} src={hhimage} alt="hh" /> */}
      <div className={styles["modal-telegram-body"]}>
        <h3 className={styles["modal-telegram-body__title"]}>ЛАНИТ_ищет</h3>
        <p className={styles["modal-telegram-body__text"]}>
          Подписывайтесь на наш телеграм-канал, чтобы не пропустить горячие
          вакансии и актуальные стажерские программы.
        </p>
        {/* <p className={styles["modal-telegram-body__text"]}>
        ЛАНИТ участвует в Рейтинге работодателей HeadHunter. Поддержите нас – проголосуйте за ЛАНИТ!
        </p> */}
        <a
          className={styles["modal-telegram-body__link"]}
          href="https://t.me/lanit_in_search"
          target="blank"
        >
          <Button>Подписаться</Button>
        </a>
        {/* <a
          className={styles["modal-telegram-body__link"]}
          href="https://rating.hh.ru/poll/?utm_source=link&utm_medium=link&utm_campaign=members"
          target="blank"
        >
          <Button className={styles["modal-telegram-body-button"]}>Голосовать</Button>
        </a> */}
      </div>
    </div>
  );
};
