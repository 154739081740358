import { VkIcon, DzenIcon, TelegramIcon } from "../Icons/Footer";
 
export const footerSocialData = [
  {
    color: "#07B6F0",
    icon: <TelegramIcon/>,
    link: "https://t.me/lanit_in_search"
  },
  {
    color: "#6584AC",
    icon: <VkIcon/>,
    link: "https://vk.com/lanit_life"
  },
  {
    color: "#42616E",
    icon: <DzenIcon/>,
    link: "https://dzen.ru/lanit"
  }
]