import { SVGProps } from "react";

export const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="23"
    fill="none"
    viewBox="0 0 26 23"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth="0.5"
      d="M19.362 21.72a.704.704 0 01-.448-.16l-8.18-6.702a.711.711 0 01-.027-1.076l6.07-5.478a.707.707 0 011.003.05c.264.292.24.74-.051 1.003l-5.46 4.925 7.542 6.178a.71.71 0 01-.449 1.26z"
    ></path>
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth="0.5"
      d="M20.224 21.983c-.377 0-.781-.117-1.206-.352l-4.664-3.748-2.39 2.204c-.332.325-.738.72-1.546.72-.993 0-1.224-.524-1.376-1.062-.027-.092-.057-.2-.101-.324l-1.712-5.633-4.905-1.53c-1.155-.355-1.31-1.063-1.323-1.35-.02-.477.229-1.157 1.524-1.676L23.6 1.1a1.525 1.525 0 011.72.459c.288.353.4.813.306 1.262l-3.641 17.422c-.326 1.57-1.341 1.74-1.761 1.74zm-5.9-5.747c.157 0 .314.053.444.158l5.038 4.062c.652.311.71-.12.79-.502l3.641-17.422-.089-.115-21.1 8.136c-.249.1-.412.197-.511.27.052.025.122.052.208.08l5.268 1.643a.715.715 0 01.467.471l1.808 5.956c.047.125.086.267.12.386a.19.19 0 01.008.027c.188-.002.288-.053.55-.31l2.876-2.654a.721.721 0 01.481-.186z"
    ></path>
  </svg>
);
