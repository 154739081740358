import styles from "../Footer/Footer.module.scss";

export const LineFooter = () => {
  return (
    <svg
      width="340"
      height="531"
      viewBox="0 0 340 531"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles["footer-line"]}
    >
      <path
        d="M223.07 1.02763C202.657 237.71 51.5552 371.419 7.57019 314.528C-36.4148 257.636 345.266 229.713 336.201 377.411C331.27 457.764 221.001 490.445 117.57 527.046"
        stroke="white"
        strokeWidth="7"
      />
    </svg>
  );
};