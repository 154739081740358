import { useState, useEffect } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { FeatureBlock } from "./components/FeatureBlock";
import { SearchBlock } from "./components/SearchBlock";
import { ProjectBlock } from "./components/ProjectBlock";
import { CarouselBlock } from "./components/CarouselBlock";
import { AboutBlock } from "./components/AboutBlock";
import { MobileMenu } from "./components/MobileMenu";
import { TopButton } from "./components/TopButton";
import { TelegramModal } from "./components/TelegramModal";

export const LandingIntern = () => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const popupTimer = setTimeout(() => {
      setOpenModal(true);
    }, 5000);

    return () => clearTimeout(popupTimer);
  }, [])

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleToggler = () => {
    setOpenMenu(!isOpenMenu);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  }

  const onTopWindow = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }



  return (
    <div style={{overflow: "hidden", position: 'relative'}}>
      <TelegramModal onClose={onCloseModal} isOpen={isOpenModal}/>
      <MobileMenu isOpenMenu={isOpenMenu} />
      <Header handle={handleToggler} isOpen={isOpenMenu} />
      <SearchBlock />
      <AboutBlock />
      <CarouselBlock />
      <ProjectBlock />
      <FeatureBlock />
      <Footer />
      {
        scroll > 600 && <TopButton onClick={onTopWindow}/>
      }
    </div>
  );
};
