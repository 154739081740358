import styles from "../AboutBlock/AboutBlock.module.scss";

export const LineAbout = () => {
  return (
    <svg
      width="1828"
      height="1480"
      viewBox="0 0 1828 1480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles["about-line"]}
    >
      <path
        d="M4 1178C149.5 1504.5 1184 1543.5 1534 1388C1884 1232.5 1868.5 934.5 1739 825C1609.5 715.5 1461.45 726.5 1330 770C1258.21 793.757 966.058 839.932 887 625.5C850.5 526.5 941.297 523.621 923 608.5C887 775.5 719.196 871.957 495 790C228.606 692.617 24.1591 253.655 116.913 2"
        stroke="#07B6F0"
        strokeWidth="7"
      />
    </svg>
  );
};