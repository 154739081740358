import classNames from "classnames";
import styles from "./Slider.module.scss";

interface SlideProps {
  imageUrl?: string;
  type?: string;
  text?: string;
}

export const Slide = ({ type, imageUrl, text }: SlideProps) => {
  return (
    <div
      className={classNames(styles["slider-slide"], {
        [styles["slider-slide-body--simple"]]: type === "simple",
        [styles["slider-slide-body--full"]]: type === "full",
      })}
    >
      <div
        className={styles["slider-slide-img"]}
        style={{
          background: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
      ></div>
      <div className={styles["slider-slide-body"]}>
        {type === "full" && (
          <p className={styles["slider-slide-body__text"]}>{text}</p>
        )}
      </div>
    </div>
  );
};
