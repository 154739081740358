import { SVGProps } from "react";

export const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#B2C1CE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2z"
    ></path>
    <path
      stroke="#B2C1CE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 7l-8.97 5.7a1.94 1.94 0 01-2.06 0L2 7"
    ></path>
  </svg>
);
