import classNames from "classnames";
import styles from "./TopButton.module.scss";
import { ArrowTopIcon } from "../Icons/ArrowTop";

interface TopButtonProps {
  onClick?: () => void;
  className?: string;
}

export const TopButton = ({ className, onClick }: TopButtonProps) => {
  return (
    <div onClick={onClick} className={classNames(styles["top-button"], className)}>
      <ArrowTopIcon />
    </div>
  );
};
