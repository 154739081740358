import { useState } from "react";
import classNames from "classnames";
import styles from "./Header.module.scss";
import { Container } from "../Container";
import { HeaderLogo } from "./HeaderLogo";
import { HeaderSocial } from "./HeaderSocial";
import { HeaderList } from "./HeaderList";
import { HeaderContact } from "./HeaderContact";
import { CloseIcon, BurgerIcon } from "../Icons";
interface HeaderProps {
  className?: string;
  isOpen?: boolean | undefined;
  handle?: any;
}

export const Header = ({ className, isOpen, handle }: HeaderProps) => {
  const [isOpenMenu, setOpenMenu] = useState(false);

  const handleToggler = () => {
    setOpenMenu(!isOpenMenu);
  };

  return (
    <header
      className={classNames(
        styles["header"],
        {
          [styles["header__active"]]: isOpen === true,
        },
        className
      )}
    >
      <Container>
        <div className={styles["header-block"]}>
          <a href="/" style={{textDecoration: 'none'}}><HeaderLogo /></a>
          <HeaderList />
          <HeaderSocial />
          <HeaderContact />
          <div onClick={handle} className={styles["header-toggler"]}>
            {isOpen ? <CloseIcon /> : <BurgerIcon />}
          </div>
        </div>
      </Container>
    </header>
  );
};
