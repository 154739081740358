import { SVGProps } from "react";

export const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="20"
    fill="none"
    viewBox="0 0 26 20"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth="2"
      d="M5.99 1h14.02C22.69 1 25 3.382 25 6.494v7.013C25 16.617 22.691 19 20.01 19H5.99C3.31 19 1 16.618 1 13.507V6.494C1 3.382 3.309 1 5.99 1z"
    ></path>
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 13V7l6 3.034L11 13z"
    ></path>
  </svg>
);
