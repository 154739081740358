import classNames from "classnames";
import styles from "./Header.module.scss";
import { LogoIcon } from "../Icons/Logo";

interface HeaderLogoProps {
  className?: string;
}

export const HeaderLogo = ({className}:HeaderLogoProps) => {
  return (
    <div className={classNames(styles['header-logo'], className)}>
      <LogoIcon/>
      <div className={styles['header-logo-text']}>
        {/* <p className={styles['header-logo-text__title']}>Ланит</p>
        <p className={styles['header-logo-text__pretitle']}>Карьера</p> */}
      </div>
    </div>
  )
}