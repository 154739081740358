import { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./Searchfield.module.scss";
import { Textfield } from "../Textfield";
import { SearchIcon } from "../Icons";
import { Button } from "../Buttons";

interface SearchfieldProps {
  className?: string;
}

export const Searchfield = ({ className }: SearchfieldProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [errorQuery, setErrorQuery] = useState(false);
  const [responseLink, setResponseLink] = useState(`https://job.lanit.ru/vacancy/Pages/new-search.aspx#k=${searchQuery}`);

  const handleSearchValue = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const onFindQuery = (e: any) => {
    if (searchQuery.length === 0) {
      setErrorQuery(true);
      e.preventDefault();
    } else if (searchQuery.length !== 0 || e.keyCode === 13) {
      setResponseLink(`${window.location.origin}/vacancy/Pages/new-search.aspx#k=${searchQuery}`);
      setErrorQuery(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (e: any) => {
      if(e.code === "Enter") {
        const link = document.querySelector(".link") as HTMLElement;
        link.click();
      }
    })
  })

  return (
    <div className={classNames(styles["searchfield-wrapper"], className)}>
      <Textfield
        className={styles["searchfield"]}
        onChange={handleSearchValue}
        value={searchQuery}
        placeholder="Поиск по профессии или навыку"
        error={errorQuery}
        helperText={errorQuery ? "Поле обязательно к заполнению" : ""}
      />
      <a className="link" id="link" onClick={onFindQuery} target="blank" href={responseLink}>
        <Button
          style={{ padding: "0" }}
          className={styles["searchfield__button"]}
        >
          <SearchIcon />
        </Button>
      </a>
    </div>
  );
};
