import { SVGProps } from "react";

export const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="98"
    height="98"
    fill="none"
    viewBox="0 0 98 98"
    {...props}
  >
    <circle cx="49" cy="49" r="49" fill="#07B6F0"></circle>
    <path
      fill="#fff"
      d="M58.607 72.465c-.357 0-.72-.119-1.015-.363L39.076 56.934a1.61 1.61 0 01-.591-1.204c-.01-.47.183-.916.53-1.23l13.741-12.403a1.6 1.6 0 012.268.116 1.603 1.603 0 01-.115 2.268L42.552 55.63l17.07 13.985a1.606 1.606 0 01-1.015 2.85z"
    ></path>
    <path
      fill="#fff"
      d="M60.558 73.063c-.852 0-1.767-.267-2.728-.797l-10.557-8.485-5.41 4.99c-.752.735-1.671 1.628-3.5 1.628-2.248 0-2.772-1.185-3.116-2.403-.06-.209-.128-.453-.228-.732l-3.875-12.752-11.103-3.464c-2.616-.803-2.966-2.406-2.995-3.055-.045-1.08.518-2.619 3.451-3.794l47.704-18.407c1.366-.533 2.969-.103 3.894 1.038.652.8.903 1.84.691 2.856l-8.241 39.438c-.739 3.553-3.036 3.939-3.987 3.939zM47.202 60.054c.357 0 .713.119 1.006.357l11.405 9.195c1.475.703 1.607-.273 1.79-1.138l8.24-39.434-.202-.26L21.68 47.19c-.562.225-.931.446-1.156.61.119.058.276.12.472.18l11.923 3.72c.504.161.903.56 1.057 1.067l4.093 13.481c.106.283.196.604.273.874a.43.43 0 01.016.061c.428-.003.652-.119 1.247-.7l6.509-6.008c.308-.276.697-.421 1.09-.421z"
    ></path>
  </svg>
);
