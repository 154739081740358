import classNames from "classnames";
import styles from "./Header.module.scss";
import { headerListData } from "./header.data";

interface HeaderListProps {
  className?: string;
  onClick?: any;
}

export const HeaderList = ({ className, onClick }: HeaderListProps) => {
  return (
    <div className={classNames(styles["header-list"], className)}>
      {headerListData.map((item, index) => {
        return (
          <a
            key={index}
            href={item.link}
            className={styles["header-list__item"]}
            target="blank"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              if (item.onClick) {
                e.preventDefault();
                item.onClick();
              }
            }}
          >
            {item.text}
          </a>
        );
      })}
    </div>
  );
};
